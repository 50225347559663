<template>
  <div v-if="element.type" class="form-item">
    <component :is="getBlockComponent(element.type, 'FormItem')" v-if="element.type" :form-errors="formErrors" :element="element" :prefilled-inputs="prefilledInputs" />
  </div>
</template>
<script setup lang="ts">
import { type WebformElementFragment, type WebformSubmissionValidationErrorFragment } from '#graphql-operations'
const { getBlockComponent } = useGetBlockComponent()

export interface Props {
  element: WebformElementFragment
  formErrors?: WebformSubmissionValidationErrorFragment[]
  prefilledInputs?: InputPrefill[]
}

withDefaults(defineProps<Props>(), {
  formErrors: () => {
    return []
  },
  prefilledInputs: () => {
    return []
  }
})

</script>

<style lang="scss">
@use "~/assets/sass/tools";

.form-item {
  margin-top: calc(var(--space--sm));

  &:first-child {
    margin-top: 0;

    .form-item-radios {
      legend {
        padding-top: 0;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
</style>
